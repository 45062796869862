.about-carousel .carousel-item-1 {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-weight: 500;
}

.about-carousel .carousel-item-1 .label{
    font-size: 54px;
    color: white;
    text-align: center;
}


.about-carousel .carousel-item-1 .description{
    text-align: center;
    max-width: 890px;
    line-height: 40px;
    font-size: 30px;
}

.about-carousel .carousel-item-1 .details-button{
    width: 149px;
    height: 40px;
    background: #FA8C16;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 6px;
    font-size: 16px;
}

@media (min-width: 320px) and (max-width:390px)  {
    .about-carousel .carousel-item-1 .details-button{
        width: 100%;
    }

}

@media (min-width: 460px) and (max-width:500px) {
    .about-carousel .carousel-item-1 .details-button {
        width: 350% ;
    }
}

@media  (max-width: 700px) {
    .about-carousel .carousel-item-1 .label{
        font-size: 32px;
    }

    .about-carousel .carousel-item-1 .description{
        text-align: left;
        font-size: 20px;
    }

    /*.about-carousel .carousel-item-1 {*/
    /*    align-items: flex-start;*/
    /*}*/

}

@media (max-width: 1120px) and (min-width: 700px) {
    .about-carousel .carousel-item-1 .label{
        font-size: 28px;
    }

    .about-carousel .carousel-item-1 .description-2{
        font-size: 24px;
    }
  
}
@media (max-width:  1420px) and (min-width: 1120px) {
    .about-carousel .carousel-item-1 .label{
        font-size: 40px;
    }

    .about-carousel .carousel-item-1 .description-2{
        font-size: 24px;
    }
  
}

@media (min-width: 320px) and (max-width: 824px) {
    .about-carousel .carousel-item-1 {
        text-align: center;

    }

    .about-carousel .carousel-item-1 .description {
        text-align: center !important;
    }

    .about-carousel .carousel-item-1 .label {
        text-align: center !important;
    }
}