.map-modal .markItem {
    padding: 24px;
}

.map-modal .title {
    font-size: 18px;
    font-weight: bold;
    color: #002766;
    line-height: 28px;
    margin-bottom: 15px;
}

.map-modal .description {
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: #2D4C68;
    line-height: 24px;
    margin-bottom: 15px;
}

.map-modal .markItem .address {
    display: flex;
    align-items: center;
}

.map-modal .markItem .address .text {
    color: #2D4C68;
    opacity: 0.6;
    font-weight: 600;
}

.map-modal .content {
    margin-bottom: 15px;
}

.map-modal .bt {
    font-weight: 600;
    height: 40px;
    width: 100%;
    color: #FA8C16;
    border-color: #FA8C16;
    border-radius: 8px;
}

@media (max-width: 630px) {
    .map-modal .gm-style-iw-d {
        width: 100%;
    }
}