.challenge-page .banner {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.challenge-page .banner .title {
    font-weight: bold;
    font-size: 54px;
    line-height: 64px;
    display: inline-block;
    color: #FFFFFF;
}

.challenge-page .challenge-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
}

.challenge-page .challenge-description .title {
    width: 620px;
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    color: #2D4C68;
}

.challenge-page .challenge-description .text {
    width: 620px;
    font-size: 18px;
    line-height: 28px;
    color: #2D4C68;
    opacity: 0.9;
}

.challenge-page .challenge-description .webinar-items {
    margin-top: 8px;
}

.challenge-page .challenge-description .webinar-items .text span {
    color: #2D4C68;
    text-decoration: underline;
}

@media (max-width: 1200px) {
    .challenge-page .challenge-description .text {
        font-size: 14px;
        width: 100%;
    }

    .challenge-page .banner .title {
        font-size: 40px;
        text-align: center;
    }

    .challenge-page .challenge-description .title {
        font-size: 20px;
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 824px) {

    .challenge-page .banner .title {
        font-size: 36px!important;
        text-align: center;
    }

    .challenge-page .challenge-description {
        margin-top: 21px !important;
    }
    .challenge-page .challenge-description .title {
        text-align: center;
    }

    .challenge-page .title .text {
        font-size: 36px;
        text-align: center;
    }

    .challenge-page .social-info {
        height: 10px;
        display: contents !important;
        justify-content: space-evenly;
        margin-bottom: 5px;
    }

    .challenge-page .help-button {
        justify-content: space-around;
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
}


.challenge-page .social-media {

    display: flex;
    height: 30px;
    width: 300px;
    align-self: center;
}

.challenge-page .social-media .links {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    margin-left: 5px;
}

.challenge-page .social-media .icon {
    color: #2D4C68;
    font-size: 1.3em;
}

.challenge-page .social-media .text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #2D4C68;
    vertical-align: center;
}
.challenge-page .social-info {
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 2%;
    margin-top: 2%;
}

.challenge-page .help-button {
    display: flex;
    align-items: center;
}
.challenge-page .content-text {
    width: 620px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #2D4C68;
    opacity: 0.9;
    margin-bottom: 25px;
}
.challenge-page .donate-button {
    width: 220px;
    height: 40px;
    font-size: 16px;
    border-radius: 6px;
    background: #FA8C16;
    color: #FFFFFF;
}

