.about-description {
    align-self: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 620px;
    margin-top: 100px;
    color: #2D4C68;
}

.about-description .label {
    font-size: 28px;
    font-weight: 700;
}

.about-description .text {
    font-size: 18px;
    opacity: 0.9;
}

@media (max-width: 700px) {
    .about-description {
        align-self: flex-start;
        text-align: left;
    }
}