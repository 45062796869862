.restore-password {
  color: #fa8c16;
  text-align: center;
  font-style: normal;
  margin-bottom: 4%;
  text-decoration: underline;
}
@media screen
and (max-device-width: 1000px) {

   .reset-button {
     color: #fa8c16;
    padding-top: 0px;
    display: flex;
    padding-inline-end: initial;
  }

    .restore-password-button {
     color: #fa8c16;
    padding: 10px 34px;
    position: static;
    font-size: 18px;
    color: #FA8C16 !important;
  }
}

@media screen
and (min-device-width: 1000px) {
   .reset-button {
     color: #fa8c16;
    padding-top: 0px;
    display: flex;
    padding-right: 200px !important;
    padding-inline-end: initial;
  }

    .restore-password-button {
    padding: 10px 34px;
    position: static;
    font-size: 18px;
    color: #FA8C16 !important;
  }
}


.user-reset-column {
  color: #fa8c16;
  display: flex;
  flex-direction: column;
  position: inherit;
  align-content: center;
  align-items: center;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;