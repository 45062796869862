.verification-message {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #2D4C68;
  padding-top: 100px;
  vertical-align: center;
  justify-content: center;
  text-align: center;
}

.login-div{
  vertical-align: center;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;