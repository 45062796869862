.user-club-content {
  padding-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 625px) {
  .cards {
    min-width: 0;
  }
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;