.center-card {
  border-radius: 16px;
  box-shadow: 0px 100px 80px rgba(1, 16, 44, 0.03), 0px 12.5216px 10.0172px rgba(1, 16, 44, 0.035);
  cursor: pointer;
  justify-content: space-between;
  margin-top: 40px;
  background-color: #ffffff;
  height: 148px;
  padding-top: 10px;
}

.ant-card-body{
  width: 100%;
}
.center-card .ant-card-body {
  height: 148px;
  display: block;
  justify-content: space-between;
}

.center-information {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.center-info {
  display: flex;
}

.center-card .center-left .center-title {
  padding-left: 8px;
  color: #2D4C68;
  opacity: 0.6;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}


.center-card .center-left .center-name {
  padding-left: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #2D4C68;
}

.center-card .center-left .center-categories {
  padding-top: 21px;
}

.center-card .center-right {
  display: block;
}

.center-card .center-edit-button {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}


.center-card .ant-btn-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2E69C9;
  border: none;
  display: flex;
  align-items: center;
}

.center-card .anticon-arrow-right {
  padding-left: 11px;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
}

.center-address {
  color: #2D4C68;
  margin-left: 15px;
  width: 100%;
  height: 24px;
  display: flex;
  font-weight: 600;
  opacity: 80%;
}

.center-address .address-icon {
  font-size: 1.5em;
  color: #FFA62C;
  margin-right: 11px;
  margin-left: 4px;
}



@media (max-width: 550px) {
  .center-card .center-right {
    display: block;
  }

  .center-card {
    display: flex;
    width: 95% !important;
  }

}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;