.mainInfo {
   height:100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   overflow: hidden;
}
.mainInfo .ant-checkbox-group {
    width:100%;
}

.mainInfo .form-fields {
    overflow: hidden;
}

.mainInfo .ant-checkbox-wrapper span {
    display:flex;
    justify-content: left;
    align-content: flex-start;
}

.mainInfo  .checkbox-item{
    height:50px;
    border-radius:15px;
    padding:5px;
    margin:2px;
    padding-left:10px;
    margin-bottom:10px;
    display:flex;
    justify-content: left;
    align-content: flex-start;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.mainInfo .btn {
    margin-left: auto;
    margin-right: 0;
}

.mainInfo .add-club-location {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FA8C16;
    vertical-align: middle;
}

.mainInfo .ant-form-item-control {
    box-sizing: border-box;
    height: 250px;
    /* overflow-y: scroll; */
}

.mainInfo .location-list {
    max-height: 270px;
    overflow-y: scroll;
}

.mainInfo ::-webkit-scrollbar {
    width: 3px;
}

.mainInfo ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}