.certificate-page {
    margin: 0 10% 10% 10%;
    /*display: flex;*/
    justify-content: center;
}

.ant-list-item {
    padding: 0 !important;
}
.import-report {
    margin-top: 30px;
    margin-right: 10%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;

}

.radio-group {
    margin: 0 0 10px 10px;
}

.radio-button {
    margin-right: 5px;
}

.load-excel-form {
    display: flex;
    flex-direction: column;
}

.warn-icon {
    color: #ec9700;
}

.error-icon {
    color: #ff4d4f;
}

.ok-icon {
    color: #4dffa3;
}

.import-excel {
    /*width: 45%;*/
    margin: 0 10% 0 10%;
}
.send-to-db {
    /*width: 45%;*/
    margin: 0 10% 0 10%;
    flex-direction: column !important;
}

.import-db-list-item {
    padding: 0;
    margin: 0;
}


/*.button {*/
/*    margin-top: 2em;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*    display: flex;*/

/*}*/
.text-hint {
    color: #002766;
    font-size: 16px;
    font-weight: 600;
}

.ant-form-item{
    margin-bottom: 10px;
}

/*.send-data-button {*/
/*    width: auto;*/
/*    display: block;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*}*/

.buttons {
    margin-top: 1em;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.dropdown {
    width: 25% !important;
}
