.about-carousel-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-carousel-block .ant-carousel {
    width: 100%;
    margin: 0 27px 0 27px;
}

.about-carousel-block .arrow {
    font-size: 21px;
    color: #fa8c16;
    margin-bottom: 72px;
}

.about-carousel-block .about-carousel {
    height: 472px;
}

.about-carousel .carousel-item {
    height: 400px;
    width: 100%;
    color: white;
    border-radius: 8px;
}

.about-carousel .slick-dots li button,
.about-carousel .slick-dots li {
    background: #c4c4c4;
    width: 12px;
    height: 12px;
    border-radius: 100%;
}

.ant-carousel .slick-dots li.slick-active button,
.about-carousel .slick-dots li.slick-active {
    background: #fa8c16;
    width: 12px;
}

@media (max-width: 750px) {
    .about-carousel-block .ant-carousel {
        margin: 0;
    }

    .about-carousel-block .arrow {
        display: none;
    }

    .about-carousel-block .carousel-item {
        padding: 16px;
    }
}

@media (min-width: 320px) and (max-width: 824px) {
    .about-carousel-block {
        margin-top: 60px;
    }
}
