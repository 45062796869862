.footer {
  padding: 49px 140px;
  justify-content: space-between;
  display: flex;
  background: #fff;
}

.footer .footer-social {
  width: 400px;
  margin-right: 20px;
}

.footer .social-media {
  margin-top: 32px;
  width: 100%;
  display: flex;


}

.footer .social-media .links {
  display: flex;
  justify-content: space-between;
  width: 250px;
}

.footer .social-media .icon {
  fill: #2D4C68;
  color: #2D4C68;
  font-size: 1.3em;
}

.footer .footer-social .footer-logo {
  background: url("/images/logofooter.png") 0 0 / contain no-repeat;
  height: 40px;
  width: 140px;
  vertical-align: middle;
}

.footer .description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 16px;
  color: #2D4C68;
}
.footer .desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 16px;
  color: #2D4C68;
}

.footer .qubstudio {
  position: inherit;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 16px;
  color: #2D4C68;
  width: 55%;
}

.footer .description .text {
  display: flex;
  flex-direction: column;
}

.footer .footer-social .footer-social-logos {
  background: url("/images/social.png") no-repeat;
  width: 160px;
  height: 22px;
  margin-top: 30px;
}

.footer .footer-partners .sponsors {
  margin-top: 35px;
}

.footer-partners .logo {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.footer-partners .logo_soft-serve {
  width: 100px;
  height: 40px;
  margin-right: 12px;
}

.footer-partners .logo img {
  width: 100%;
  height: 100%;
}

.footer .article {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2D4C68;
}

.footer-donate .footer-donate-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 16px;
  color: #2D4C68;
}

.footer-donate .donate-button {
  width: 220px;
  height: 40px;
  font-size: 16px;
  border-radius: 6px;
  background: #FA8C16;
  margin-top: 28px;
  color: #FFFFFF;
}

.donate-button:hover {
  color: white;
  background: #FFA940;
}

@media (max-width: 970px) {
  .footer {
    height: 100%;
    flex-wrap: wrap;
  }

  .footer .footer-social {
    margin-right: 20px;
  }

  .footer .footer-social {
      order: 1;
  }

  .footer .footer-partners {
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-bottom: 15px;
  }

  .footer-social .article {
    text-align: center;
  }
}

@media (max-width: 900px) {
  .footer {
    padding: 30px;
  }
}


@media (max-width: 824px) {
  .footer {
    justify-content: center;
    flex-wrap: wrap;
  }

  .footer .footer-social,
  .footer .footer-donate {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }

  .footer .footer-donate,
  .footer .footer-social {
    margin-top: 30px;
  }
}

@media (min-width: 320px) and (max-width: 824px) {
  .footer .desc {
    width: 92%;
    text-align: center;
  }

  .footer .description .text {
    display: initial;
    text-align: center;
  }

  .footer .footer-social {
    width: 365px;

  }

  .footer .qubstudio {
    text-align: center;
  }

  .footer .social-media {
    
    justify-content: center;
    margin-left: 14%;
  }
  .footer .article {
    text-align: center;
  }

  .footer .social-media .links {
    display: flex;
    justify-content: space-evenly;
    width: 210px;
  }

}

@media (max-width: 850px) {
  footer-donate {
    width: 100%;
    justify-content: center;
  }
}

@media(min-width: 851px) {
  .footer-donate {
    width: 22%;
  }
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;