.club-list .display-content .card {
    height: 418px;
    width: 296px;
    color: black;
    box-shadow: 0px 100px 80px rgba(1, 16, 44, 0.03), 0px 12.5216px 10.0172px rgba(1, 16, 44, 0.035);
    border-radius: 16px;
    padding: 15px;
    cursor: pointer;
    margin: 0 5px 28px 5px;

}
.ant-card-body{
    height: 396px;
    width: 274px;
}
.display-content{
    width: 98%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* align-content: flex-start; */
    justify-content: center;
}
.clubs-inscription{
    width: 183px;
    height: 32px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    size: 32px;
    color: #2D4C68;
    display: flex;
    align-items: center;
}