.page-comments {
    display: flex;
    justify-content: center;
    width: 100%;
    background: white;
}

.comments-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.answer-comment, .show-more-button {
    margin-top: 10px;
    margin-left: 5px;
    color: #3e77aa;
    border: none;
    padding: 0;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.answer-comment:hover, .show-more-button:hover{
    color: #236cad !important;
}
.answer-comment::after,
.show-more-button::after {
    box-shadow: none !important;
}

.reply-container {
    border-left: 1px solid #d6d6d6;

}

.reply-message {
    margin-left: 20px;
    width: calc(100% - 20px);
}

.answer-p, .show-more-p {
    padding-left: 10px;
    padding-right: 10px;
}

.ant-comment {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    margin-bottom: 20px;
}

.ant-comment-inner {
    padding: 0;
}

.page-comments .comment-list {
    border-top: 1px solid #E8E8E8;
    width: 837px;
}

.page-comments .ant-list-split .ant-list-header {
    border: none;
}

.page-comments .ant-comment-content-author-name {
    width: 100%;
    padding: 0;
}

.page-comments .author {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    align-items: center;

    width: 100%;
}

.avatar {
    object-fit: cover;
}

.page-comments .author .avatar {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 50%;
}

.name {
    font-weight: bold;
}

.datetime {
    font-size: 14px;
}

.page-comments .author-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    font-size: 16px;
    line-height: 32px;
    color: #221f1f;
    margin-left: 12px;
}

.page-comments .author-content .datetime {
    opacity: 0.6;
}

.page-comments .rating {
    margin-left: auto;
    margin-right: 20px;
}

.page-comments .rating .ant-rate-star{
    font-size: 14px;
}

.page-comments .ant-comment-content{
    font-size: 18px;
    line-height: 32px;
    color: #2D4C68;
    opacity: 0.9;
}

.page-comments .comment-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0 35px 0;
}

.page-comments .comment-header .comment-button{
    border-radius: 6px;
    border-color: #FA8C16;
    color: #FA8C16;
}

.page-comments .comment-header .comment-button:hover{
    border-color: #FFA940;
    color: #FFA940;
}

.page-comments .comment-header .comment-label {
    font-size: 28px;
    font-weight: 700;
    color: #002766;
}

.page-comments .ant-comment-content-detail {
    font-size: 14px;
    font-weight: 500;
    color: #221f1f;
    padding-left: 15px;

}

@media (max-width: 1260px) {
    .page-comments {
        padding: 0 30px 0 30px;
    }
}

@media (max-width: 700px) {
    .page-comments .author-content {
        display: flex;
        flex-direction: column;
        gap: 0;
        font-size: 18px;
        line-height: 32px;
        color: #002766;
        margin-left: 12px;
    }
}


@media (max-width: 450px) {
    .page-comments .comment-header {
        flex-direction: column;
        margin: 0;
    }
    .page-comments .comment-header .comment-button {
        margin-top: 10px;
        width: 100%;
    }
    .page-comments .ant-comment-content-detail {
        font-size: 15px;
        line-height: 20px;
    }
    .page-comments .author-content {
        font-size: 15px;
        line-height: 25px;
    }
    .page-comments .author-content .datetime {
        font-size: 12px;
    }
}