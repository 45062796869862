.modal-registration .ant-modal-body {
  height: 100%;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0;
}


.confirm-password .registration-input label {
  font-size: 5px;

}

.modal-registration .ant-form-item label:after {
  content: none;
}

.modal-registration .ant-form-item-explain {
}

.modal-registration .ant-modal-content {
  border-radius: 16px;
}

.modal-registration .registration-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #002766;
  font-weight: 700;
  border-radius: 16px;
  padding-top: 24px;
  padding-left: 32px;
}

.modal-registration .ant-form-item {
  margin: 0;
}

.modal-registration .ant-modal-close-icon {
  color: #595959;
}

.modal-registration .button-container {
  padding-top: 24px;
  display: flex;
  height: 48px;
  justify-content: center;
  background: #FFFFFF;
}

.modal-registration .ant-radio-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFF7E6;
  height: 48px;
  width: 180px;
  border: 0;
  font-size: 18px;
  line-height: 32px;
  border-radius: 4px;
}

.modal-registration .ant-radio-button-wrapper:hover,
.modal-registration .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #FFFFFF;
  color: black;
  box-shadow: 0 2px 4px rgba(51, 51, 50, 0.12);
  z-index: 1;

  .ellipse {
    background: #FA8C16;
  }
}

//
//.ant-btn-text{
//text-align: left;
//  color: #2D4C68;
//}

.modal-registration .ant-radio-button-wrapper:last-child {
  left: -5px;
}

.modal-registration .button-box {
  display: flex;
}

.modal-registration .ellipse {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background: #FFC069;
  border-radius: 50%;
  margin-right: 8px;
}

.modal-registration .user-icon {
  color: #FFFFFF;
  font-size: 21px;
}

.modal-registration .registration-content {
  flex-direction: column;
  justify-content: space-between;
}

.modal-registration .icons {
  justify-content: space-between;
  padding-top: 24px;
}

.modal-registration .items {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 32px;
  pointer-events: none;
  cursor: default;
  -webkit-filter: grayscale(50%);
  filter: grayscale(100%);
}

.modal-registration .items-active {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 32px;
}


.modal-registration .logo {
  margin-right: 12px;
  margin-left: 12px;
}


.modal-registration .registration-or {
  text-align: center;
  font-size: 18px;
  color: #595959;
}

.modal-registration .registration-column {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-registration .registration-input {
  width: 65%;
  margin-top: 18px;
}

.modal-registration .registration-input label {
  font-size: 18px;
  width: 110px;
  text-align: right;
  padding-right: 16px;
  padding-top: 10px;
  display: block;
  color: #2D4C68;
}

.confirm-password {
  margin-top: 0 !important;
}

.dead {
  margin-top: -15px;
  float: right;
}

.small-media {
  display: none;
}

.modal-registration .ant-form-item-label {
  display: contents !important;
}

.modal-registration .registration-box {
  height: 50px;
  border-radius: 6px;
}


.modal-registration .phone-icon {
  color: #8C8C8C;
  font-size: 18px;
  width: 20px;
}

.modal-registration .anticon-mail {
  color: #8C8C8C;
  z-index: 10;
  font-size: 17px;
  width: 20px;
}

.modal-registration .agreement-text{
  padding-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
  color:red;
  position: fixed;
  text-align: center;
}

.modal-registration .registration-button {
  padding: 8px 24px;
  height: 40px;
  background: #FA8C16 !important;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF !important;
}

.modal-registration .registration-button:disabled {
  opacity: 0.4;
}

.modal-registration .registration-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

.custom-class-confirmation {
  font-size: 25px;
  height: 100%;
  font-weight: bold;
  background-color:rgba(0, 0, 0, 0.5);
  border: 2px orange;
  padding-top: 50px;
}

.custom-class-confirmation .ant-message-notice-content {
  color: orange;
  height: 100%;
  background-color: white;
  border-radius: 20px!important;
  border: 2px orange;
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label > label {
    text-align: left;
    width: 100%;
    display: contents;
  }

  .dead {
    display: none;
  }

  .small-media {
    display: flex;
    width: 400px;
  }
}
@media(min-width: 320px) and (max-width: 824px){

  .modal-registration .registration-column {
    width: 100%;
  }

  .modal-registration .registration-button {
    width: 90%;
  }

  .modal-registration .registration-input {
    span: 24;
    width: 90%;
    margin-top: 18px;
  }

  .ant-form-item .ant-form-item-label > label {
    text-align: left;
    display: flex;
  }


  .confirm-password .registration-input label {
    font-size: 5px;
    text-align: left;
    width: 100%;
    display: flex;
  }


}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;