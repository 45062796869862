.about-challenge {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 120px;
}

.about-challenge .challenge-description {
    display: flex;
    flex-direction: column;
    color: #2D4C68;
    max-width: 648px;
}

.about-challenge .challenge-description .label {
    color: #2D4C68;
    font-size: 32px;
    font-weight: 700;
}

.about-challenge .speakingclub-description {
    width: 100%;
}

.about-challenge .speakingclub-description .label {
    display: flex;
    justify-content: center;
    color: #2D4C68;
    font-size: 32px;
    font-weight: 700;
}

.about-challenge .challenge-description .text {
    font-size: 18px;
    line-height: 28px;
    opacity: 0.9;
}

.about-challenge .challenge-description .materials-button {
    width: 249px;
    height: 40px;
    background: #FA8C16;
    border: none;
    color: white !important;
    margin-top: 24px;
    font-weight: 700;
    border-radius: 6px;
    font-size: 16px;
}

.about-challenge .challenge-description .materials-button:hover {
    background: #FFA940;
}

.about-challenge .image {
    margin-left: 15px;
    max-width: 512px;
    max-height: 378px;
}

.about-challenge .image > img {
    width: 100%;
    height: 100%;
}

.banner-image {
     width: 70%;
     margin-top: 5%;
     display: block;
     margin-left: auto;
     margin-right: auto;
     border-radius: 13px;
}

@media (max-width: 1200px) {
    .about-challenge {
        flex-direction: column;
    }

    .about-challenge .image {
        margin-left: 0;
    }

    .about-challenge .challenge-description {
        order: 1;
    }

    .about-challenge .challenge-description .materials-button {
        width: 100%;
    }
}

@media (min-device-width : 320px) and (max-device-width : 868px) {
    .about-challenge .challenge-description .materials-button{
        bottom:12px;
    }
    .about-challenge .challenge-description .label {
        text-align: center;
    }

}

@media (min-width: 320px) and (max-width: 375px) {
    .about-challenge .image > img {
        height: 70%;
    }
}

