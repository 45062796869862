@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4n.ttf) format('truetype');
}
.ant-layout {
  background: none;
}
.layout {
  background: linear-gradient(to bottom, #f5d5ca 0%, #fbf4e4 65%, #fbf7eb 100%);
}
.behind-header {
  position: absolute;
  height: 150px;
  width: 100%;
  background: url("images/header-bg.png");
  background-size: cover;
}
*::selection {
  background: black;
  color: white;
}
.header {
  color: #fff;
  z-index: 1;
  background: none;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 87px 0 87px;
  justify-content: flex-start;
  align-items: center;
}
.header .right-side-menu {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: auto;
}
.header .logo {
  background: url("images/logo.png") no-repeat;
  background-size: contain;
  width: 120px;
  height: 50px;
  margin-top: 18px;
  vertical-align: middle;
}
.ant-btn:active,
.ant-btn:focus {
  color: #000;
  border-color: #d9d9d9;
}
.ant-rate-star.ant-rate-star-zero .ant-rate-star-second {
  color: #777777;
}
.ant-btn:hover {
  color: #FFA940;
  border-color: #FFA940;
}
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: #FA8C16;
  color: #fff;
}
.ant-btn-primary:hover {
  background-color: #FFA940;
  color: #fff;
}
.outlined-button,
.outlined-button:active,
.outlined-button:focus {
  border-color: #FA8C16;
  color: #FA8C16;
  border-radius: 6px;
}
.outlined-button:hover {
  border-color: #FFA940;
  color: #FFA940;
}
.support-button {
  border-color: white;
  color: white;
}
.support-button:hover {
  color: #FA8C16;
  border-color: #FA8C16;
}
.flooded-button,
.flooded-button:active,
.flooded-button:focus {
  background: white;
  border: none;
  color: #FA8C16;
  font-weight: 700;
}
.flooded-button:hover {
  color: white !important;
  background: #FA8C16;
}
.show-map-button {
  height: 35px;
  margin-left: 30px;
  border-radius: 6px;
}
.header .city {
  margin: 0 1.4vw 0 4vw;
  font-size: 16px;
}
.header .city .icon {
  font-size: 1.5em;
}
.header .nav-menu {
  width: 690px;
  line-height: 2em;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: none;
}
.header .nav-menu .ant-menu-submenu-title {
  display: inline-block;
}
.header .nav-menu .sub1 .ant-menu-submenu-title {
  color: white;
  background: transparent;
  font-size: 1em;
  display: inline-block !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-active {
  color: white;
  background: rgba(255, 242, 240);
  display: inline-block !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f9b575;
  font-weight: bold;
}
.header .nav-menu .sub1 .ant-menu-item-icon {
  font-size: 20px;
}
.header .nav-menu .ant-menu-submenu,
.header .nav-menu .ant-menu-submenu-active {
  margin: 0 20px 0 0;
  border-bottom: none !important;
}
.header .nav-menu .icon {
  font-size: 18px;
  color: #fff;
}
.header .nav-menu .ant-menu-item a {
  color: white;
}
.header .ant-menu-item {
  margin: 0 10px;
}
.header .add-club-button {
  width: 149px;
  height: 40px;
  background: #FA8C16;
  border: none;
  color: white;
  margin-right: 1.4vw;
  font-weight: 700;
  border-radius: 6px;
  font-size: 16px;
}
.layout .global-content {
  z-index: 1;
  min-height: 500px;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-left: 0;
}
.search {
  display: flex;
  align-items: flex-end;
}
.search .ant-select-clear {
  background: none;
  display: inline-block;
}
.search .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  background: none;
  border: none;
  padding: 0;
  border-radius: 0;
  border-bottom: 2px solid white;
  height: 30px;
  font-size: 26px;
}
.search .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0;
  padding-right: 20px;
}
.search .ant-select-selection-placeholder,
.search .ant-select-arrow {
  color: white;
  font-size: 16px;
}
.search .clear-icon,
.search .advanced-icon {
  font-size: 18px;
  color: white;
  background: #FA8C16;
  padding: 2px;
  margin-left: 5px;
  border-radius: 4px;
  cursor: pointer;
}
.city-name-box {
  font-size: 20px;
  line-height: 0;
  display: flex;
  align-items: center;
}
.city-name-box-small-screen {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.city-name-box-small-screen .icon {
  display: none;
  color: #fff;
  font-size: 21px;
}
.city-name {
  color: white;
  padding-bottom: 5px;
  font-size: 32px;
  margin-top: 20px;
}
.tags {
  display: flex;
  flex-wrap: wrap-reverse;
}
.tags .tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 3px;
  height: 22px;
  max-width: 163px;
  overflow: hidden;
  border: none;
  padding: 1px 8px;
}
.tags .tag .icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  margin-right: 4.8px;
}
.tags .tag .name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.card .title .name {
  color: #2D4C68;
  font-size: 16px;
  font-weight: 700;
  margin-left: 7px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.icon-box {
  display: inline-flex;
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.icon {
  pointer-events: none;
  color: #fff;
  user-select: none;
  width: 24px;
  height: 24px;
}
.subItem {
  color: #000000 !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected .subItem {
  color: #fff !important;
}
.card .details-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 40px;
  width: 100%;
  color: #FA8C16;
  border-color: #FA8C16;
}
.card .center {
  display: flex;
  align-items: center;
  height: 40px;
}
.card .center .center-logo {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.card .center .center-description {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  margin-left: 8px;
}
.card .center .center-name {
  width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  color: #2E69C9;
}
.card .center .center-label {
  color: #2D4C68;
  opacity: 0.6;
}
.card .description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  width: 100%;
  overflow: hidden;
  height: 110px;
  margin-top: 15px;
  color: #2D4C68;
}
.card .with-center {
  overflow: hidden;
}
.card .with-center .description {
  -webkit-line-clamp: 3;
  height: 68px;
}
.card .details-button:hover {
  color: #002766;
  border-color: #002766;
}
.card .address {
  color: #2D4C68;
  width: 100%;
  height: 24px;
  margin: 2px 0 2px 0;
  display: flex;
  font-weight: 600;
}
.card .address .text {
  opacity: 0.6;
}
.address-small-icon {
  color: #FFA62C;
}
.card .address-icon {
  font-size: 1.5em;
  color: #FFA62C;
  margin-right: 11px;
  margin-left: 4px;
}
.card .expand-icon {
  font-size: 1em;
  margin-left: 5px;
}
.card .rating {
  margin-left: 4px;
}
.global-padding {
  padding: 20px 87px 20px 87px;
}
.full-width {
  width: 100%;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
}
.loader .bar {
  position: relative;
  height: 2px;
  background-color: white;
  border-radius: 25px;
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.lower-header-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.popConfirm-cancel-button:hover {
  background: #FE7729;
}
.popConfirm-ok-button {
  background: #0050B3;
  box-shadow: none;
}
.popConfirm-ok-button:hover {
  background: #1890FF;
}
.table .table-action {
  color: #0050B3;
  cursor: pointer;
  margin-right: 8px;
}
.ant-pagination-item a {
  color: black;
  background: white;
  border-radius: 5px;
}
.ant-pagination-item-active a,
.ant-pagination-item a:hover {
  color: white !important;
  background-color: #FA8C16;
  border-radius: 5px;
}
.pagination .ant-pagination-item:hover {
  text-decoration: none;
  background-color: #FA8C16;
}
.pagination .ant-pagination-item:focus a,
.ant-pagination-item-active {
  background-color: #FA8C16;
  border-color: #FA8C16;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0050B3;
}
.ant-dropdown-menu-item-selected {
  background: #fafafa;
  color: black;
}
.ant-table-filter-dropdown-btns .ant-btn-link {
  color: #2E69C9;
}
.ant-table-filter-dropdown-btns .ant-btn-primary {
  background: #FA8C16;
}
.ant-table-filter-dropdown-btns .ant-btn-primary:hover {
  background: #FFA940;
}
.table-header {
  padding: 12px 8px;
  background: #f0f0f0;
}
.ant-message .anticon-info-circle {
  color: #0050B3;
}
@keyframes loader-animation {
  0% {
    width: 0%;
  }
  49% {
    width: 100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}
.ant-menu-item-active {
  background-color: #FA8C16;
}
span.home-page {
  display: none;
}
span.anticon.anticon-home.icon {
  display: none;
}
.home {
  display: none;
}
@media screen and (max-width: 2500px) {
  .header .nav-menu {
    width: 700px;
    display: flex;
  }
  .header .nav-menu .ant-menu-item {
    order: 1;
  }
  .header {
    padding: 0 55px;
  }
  .ant-layout-header header {
    display: flex !important;
  }
}
@media (min-width: 1215px) and (max-width: 1390px) {
  .header .nav-menu {
    width: 700px;
    display: flex;
  }
  .header .nav-menu .ant-menu-item {
    order: 1;
  }
  .city-name-box {
    font-size: 24px !important;
  }
  .right-side-menu {
    width: 245px;
  }
  .header .city {
    margin: 0 1vw;
  }
}
@media (min-width: 1186px) and (max-width: 1214px) {
  .left-side-menu {
    display: inline-block;
  }
  .header .nav-menu {
    width: 500px;
    display: flex;
  }
  .city-name {
    font-size: 20px;
  }
  .lower-header-box {
    flex-direction: row;
    align-items: normal;
    height: -35px;
    justify-content: space-between;
  }
  .global-padding {
    padding: 0 87px 20px 87px;
  }
  .lower-header-box .city-name-box {
    justify-content: space-between;
  }
  .header {
    padding: 0 55px !important;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media (min-width: 902px) and (max-width: 1185px) {
  .header .nav-menu {
    width: 400px;
    display: flex;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
  .city-name {
    font-size: 24px;
  }
}
@media screen and (min-width: 900px) and (max-width: 976px) {
  .layout .header {
    justify-content: space-between;
  }
  .header .nav-menu {
    width: 290px;
    display: flex;
  }
  .ant-layout-header {
    padding: 0 30px !important;
  }
  .lower-header-box .city-name {
    font-size: 20px;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media (min-width: 810px) and (max-width: 901px) {
  .global-padding,
  .layout .header {
    padding: 0 10px 0 10px;
  }
  .layout .header {
    justify-content: space-between;
  }
  .header .nav-menu {
    width: 290px;
    display: flex;
  }
  .lower-header-box .city-name {
    font-size: 24px;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media screen and (max-width: 809px) {
  .global-padding,
  .layout .header {
    padding: 0 10px 0 10px;
  }
  .content-clubs {
    min-width: 0;
  }
  .header .nav-menu {
    width: 180px;
  }
  .lower-header-box {
    margin-top: 10px;
    display: flow-root;
    align-items: flex-end;
  }
  .lower-header-box .city-name {
    font-size: 24px;
    padding-left: 40px;
  }
  .search {
    font-size: 14px;
    line-height: 24px;
    padding-left: 40px;
  }
  .lower-header-box .city-name-box-small-screen .icon {
    display: none;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 976px) {
  .layout .header {
    justify-content: space-between;
  }
  .header .nav-menu {
    width: 290px;
    display: flex;
  }
  .ant-layout-header {
    padding: 0 30px !important;
  }
  .lower-header-box .city-name {
    font-size: 20px;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media screen and (max-width: 706px) {
  .content-clubs {
    min-width: 0;
  }
  .header .nav-menu {
    width: 40px;
  }
  .lower-header-box {
    display: flow-root;
    align-items: flex-end;
  }
  .lower-header-box .city-name {
    font-size: 24px;
    padding-left: 40px;
  }
  .search {
    font-size: 14px;
    line-height: 24px;
    padding-left: 40px;
  }
  .header .nav-menu .ant-menu-submenu-title {
    display: inline-block !important;
  }
}
@media (min-device-width: 414px) and (max-device-width: 500px) {
  span.anticon.anticon-home.icon {
    display: inline-block;
  }
  span.home-page {
    display: inline-block;
  }
  li.ant-menu-item.ant-menu-item-only-child.home {
    display: inline-block;
  }
  .header .logo {
    display: none;
  }
  .header .add-club-button {
    display: flex;
    width: 149px;
    height: 32px;
  }
  .header .right-side-menu {
    margin-left: 30px;
  }
  .lower-header-box .city-name {
    font-size: 21px;
    margin-left: -24px;
  }
}
@media (min-device-width: 375px) and (max-device-width: 413px) {
  span.anticon.anticon-home.icon {
    display: inline-block;
  }
  span.home-page {
    display: inline-block;
  }
  li.ant-menu-item.ant-menu-item-only-child.home {
    display: inline-block;
  }
  .lower-header-box .city-name {
    font-size: 21px;
    margin-left: -24px;
  }
  .header .add-club-button {
    display: flex;
    width: 149px;
    height: 32px;
  }
  .header .logo {
    display: none;
  }
}
@media (min-device-width: 320px) and (max-device-width: 374px) {
  span.anticon.anticon-home.icon {
    display: inline-block;
  }
  span.home-page {
    display: inline-block;
  }
  li.ant-menu-item.ant-menu-item-only-child.home {
    display: inline-block;
  }
  .header .logo {
    display: none;
  }
  .lower-header-box .city-name {
    font-size: 18px;
    margin-left: -24px;
  }
}
@media (min-device-width: 320px) and (max-device-width: 359px) {
  .header .right-side-menu {
    margin-left: -24px;
  }
  .header .city {
    margin-left: 16px;
  }
}
@media (min-device-width: 350px) and (max-device-width: 374px) {
  .header .right-side-menu {
    margin-left: -10px;
  }
  .ant-avatar-lg.ant-avatar-icon {
    margin-right: -10px;
  }
}
@media (min-device-width: 337px) and (max-device-width: 349px) {
  .header .right-side-menu {
    margin-left: -10px;
  }
  .header .add-club-button {
    width: 139px;
  }
}
@media (min-device-width: 330px) and (max-device-width: 336px) {
  .header .right-side-menu {
    margin-left: -20px;
  }
  .header .add-club-button {
    width: 139px;
  }
}
@media (min-device-width: 337px) and (max-device-width: 374px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-left: 8px;
  }
}
@media (max-width: 500px) {
  .lower-header-box .show-map-button {
    margin: 10px 0 5px 40px;
  }
  .search-container {
    width: 95vw;
  }
  .search-container .search {
    width: 100vw;
    background-color: #ffc230;
    margin-left: -10px;
    padding-bottom: 10px;
  }
}
@media (min-width: 319px) and (max-width: 790px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-left: 10px;
  }
  .lower-header-box .city-name-box-small-screen .icon {
    display: none;
  }
  .lower-header-box .show-map-button {
    margin-top: -6%;
    margin-left: 4%;
    margin: 10px 0 5px 10px;
  }
  .city-name-box {
    margin-top: -20px;
  }
  .header .nav-menu {
    width: 10px;
  }
  .header .city .icon {
    font-size: 1.1em;
  }
  .lower-header-box .city-name {
    font-size: 21px;
    margin-left: -31px;
  }
  .lower-header-box {
    display: inline-table;
    align-items: flex-end;
  }
  .search {
    font-size: 14px;
    line-height: 24px;
    padding-left: 11px;
  }
  .search .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    margin-left: 1% !important;
  }
  .search {
    margin-left: -10px;
    padding-left: 20px;
  }
}
@media (max-width: 500px) {
  .city-name-box {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}
.ant-checkbox-group {
  display: block !important;
}
.show-map-button:focus {
  color: #fa8c16 !important;
}
.show-map-button:hover {
  color: #fff !important;
}
.ant-menu-item a {
  color: #000;
}
.challenge-text {
  color: #fff;
}
.ant-menu-submenu-vertical .challenge-text {
  color: #000;
}
.ant-menu-submenu-vertical .anticon-crown {
  color: #000 !important;
}
.challenge-text:hover {
  color: #fff;
}
:where(.css-dev-only-do-not-override-50es5f).ant-menu .ant-menu-item .ant-menu-item-icon,
:where(.css-dev-only-do-not-override-50es5f).ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  color: #fff;
}
