.orange-btn:hover {
    background: #fa8c16;
    color: white;
    border-color: #fa8c16;
}

.orange-btn:focus {
    background: white;
    color: black;
    border-color: #d9d9d9;
}

.tab {
    display: inline-block;
    margin-left: 2em;
}

.ul_local {
  margin-top: 0;
  margin-bottom:0;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.li_local {
  display: inline-block;
}
