.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.childIcon {
    width: 45px;
    margin-right: 5px;
}

.customCheckbox {
    font-size: 16px;
}

.textArea, .commentInput {
    width: 100% ;
    border-radius: 6px;
    border-color: #D9D9D9;
    font-size: 16px;
}

.addChildrenBtn {
    margin: 10px 0;
    margin-left: 20px; 
}

.signUpForClubModal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    user-select: none;
}

.content {
    display: flex;
    flex-direction: column;
}

.typeName {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 15px;
    text-align: center;
    color: #003A8C;
}

.label {
    font-size: 16px;
    margin-bottom: 5px;
    width: 100%;
    white-space: normal;
    color: #595959;
    padding: 0;
}

.formItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    text-align: center;
    justify-content: space-between;
}

.formButton {
    float: right;
    height: 40px;
    background: #FA8C16 !important;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #FFFFFF !important;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}   