.controlsContainer {
    display: flex;
    justify-content: space-between;
}

.searchContainer {
    width: 30%;
    display: flex;
}

.searchInput {
    padding-right: 15px;
}

.filtersContainer {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 32px;
    color: #2D4C68;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;