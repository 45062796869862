.ant-form-item-explain,
.ant-form-item-extra{
    min-height: 0;
}
.modal-add-club .add-club-content {
    margin-top: 32px;
}

/*.add-club-content .ant-form-item-explain > div {*/
/*    display: none;*/
/*}*/

.add-club-contacts .add-club-contact .ant-form-item-explain > div {
    display: block;
}

.add-club-content .add-club-row label {
    font-size: 18px;
    text-align: left;
    padding-right: 16px;
    display: block;
    color: #2D4C68;
}

.add-club-content .add-club-row label:after {
    content: none;
}

.add-club-content .add-club-row {
    flex-direction: column;
    text-align: left;
}

.add-club-content .add-club-input {
    height: 40px;
    border-radius: 6px;
    border-color: #D9D9D9;
}

.add-club-content .add-club-input:hover {
    border-color: #a4a4a4 !important;
}

.add-club-content .add-club-categories {
    width: 100%;
    /*display: flex;  */
    flex-wrap: wrap;
    justify-content: space-between;
}

.add-club-content .add-club-categories label {
    width: 100%;
    font-size: 14px;
    white-space: normal;
    color: #595959;
    margin: 0;
    padding: 0;
}

.add-club-content .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0050B3;
    border-color: #0050B3;
}

.ant-checkbox-inner:hover {
    background-color: #0050B3 !important;
    border-color: #0050B3;
}

.add-club-content .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner:hover {
    border-color: #0050B3;
}

.add-club-content .add-club-age {
    color: #595959;
    display: flex;
    align-items: center;
}

.add-club-content .add-club-age .input-age {
    margin: 0 16px 0 8px;
    border: 1px solid #D9D9D9;
    color: #595959;
    border-radius: 6px;
    width: 43px;
    height: 40px;
}

.add-club-content .ant-input-number-input-wrap {
    height:100%;
    display:flex;
    align-items: center;
}
.add-club-content .ant-input-number-input-wrap input{
    text-align: center;
}

.add-club-content .add-club-categories label{
    display: flex;
}

.add-club-content .add-club-age .ant-input-number-handler-wrap {
    width: 12px;
    border-radius: 0 6px 6px 0;
}

.add-club-content .add-club-age .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.add-club-content .add-club-age .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
    margin-right: -4px;
}

.add-club-content .add-club-select {
    width: 270px;
}

.add-club-content .add-club-select .ant-select-selector {
    height: 40px;
    border-radius: 6px;
    align-items: center;
}

.add-club-content .add-club-inline {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.add-club-content .add-club-upload .icon {
    margin-right: 11px;
}

.add-club-content .add-club-upload {
    color: #2E69C9;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.add-club-content .add-club-upload:hover {
    color: #2E46C9;
}

.add-club-content .editor-container {
    height: 200px;
}

.add-club-content .add-club-input .icon {
    width: 17px;
    min-width: 17px;
    height: 17px;
}

.add-club-content .add-club-contacts .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.add-club-content .add-club-contact {
    width: 270px;
}

.add-club-content .add-club-location-list td {
    width: 65px;
}

.add-club-content .add-club-location-list {
    max-height: 170px;
    overflow: auto;
}

.add-club-content .add-club-location-list::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: white;
}

.add-club-content .add-club-location-list::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 15px;
    opacity: 0.5;
}

.modal-add-club .add-club-locations {
    margin: 0;
}

.add-club-content .location-info {
    width: 100%;
}

.add-club-content .add-club-location {
    font-weight: 700;
    color: #FE7729;
    cursor: pointer;
}

.add-club-content .info-icon {
    color: #D9D9D9;
}

.add-club-content .ant-switch-checked {
    background: #0050B3;
}

.description-step .editor-space {
    color:#595959;
}


@media screen and (max-width:578px)  {

    .add-club-content .add-club-inline {
        display: block;
    }
}

.ant-checkbox-checked:hover {
  background-color: #0050B3 !important;
    border-color: #0050B3;
 }