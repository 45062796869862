.modal-login .ant-modal-body {
  height: 100%;
  border-radius: 16px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.modal-login .login-input label {
  font-size: 18px;
  width: 94px;
  text-align: right;
  padding-right: 16px;
  padding-top: 7px;
  display: block;
  color: #2D4C68;
}

.modal-login .ant-form-item-explain {
  display: none;
}

.modal-login .ant-modal-content {
  border-radius: 16px;
}

.modal-login .login-header {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #002766;
  font-weight: 700;
  border-radius: 16px;
  padding-top: 24px;
  padding-left: 32px;
}

.modal-login .ant-form-item {
  margin: 0;
}

.modal-login .ant-modal-close-icon {
  color: #595959;
}

.modal-login .user-icon {
  color: #FFFFFF;
  font-size: 21px;
}

.modal-login.icons {
  justify-content: space-between;
  padding-top: 24px;
}

.modal-login .items {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 32px;
}

.modal-login .logo {
  margin-right: 12px;
  margin-left: 12px;
}

.modal-login .login-row {
  width: 88%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-login .login-column {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-login .login-or {
  text-align: center;
  font-size: 18px;
  color: #595959;
}

.modal-login .login-input {
  height: 100%;
  width: 65%;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}

.modal-login .login-box {
  height: 40px;
  border-radius: 6px;
}

.modal-login .login-row .label {
  font-size: 18px;
  padding-right: 16px;
  color: #2D4C68;
}

.modal-login .phone-icon {
  color: #8C8C8C;
  font-size: 18px;
  width: 20px;
}

.modal-login .anticon-mail {
  color: #8C8C8C;
  z-index: 10;
  font-size: 17px;
  width: 20px;
}

.modal-login .login-button {
  padding: 8px 24px;
  height: 40px;
  background: #FA8C16 !important;
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF !important;
}

.modal-login .login-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
}

@media (max-width: 575px) {
  .modal-login .ant-form-item .ant-form-item-label > label {
    text-align: left;
    width: 100%;
  }
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;