@media (max-width: 1200px) {

  .user-page .ant-layout-sider-dark {
    min-width: 100% !important;
    width: 382px !important;
    //min-width: 100%;
    //width: 100%;
    background: #FFFFFF;
  }
}

@media (min-width: 1200px) {

  .user-page .ant-layout-sider-dark {
    min-width: 280px !important;
    width: 280px !important;
    background: #FFFFFF;
  }
}
.menu-component {
  padding-top: 30px;
  padding-left: 30px;
  height: 400px;
}

.menu-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #2D4C68;
  opacity: 0.6;
}

.sider-profile {
  padding-top: 40px;
  font-size: 15px;
}

.menu-item {
  color: #2D4C68;
  border-radius: 4px;
}

.icon-user {
  padding-right: 7px;
  font-size: 20px;
  vertical-align: middle;
}

.user-prof, .sider-message {
  color: #D46B08;
}

.sider-message {
  padding-top: 36px;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #2D4C68;
}

.icon-message {
  margin-right: 6px;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 1200px) {
  .user-page .ant-layout-sider-dark {
    position: absolute;
    height: 124px !important;
    //width: 100%;
    //max-width: 100%;
    //width: 10000% !important;
    //max-width: 10000% !important;
  }

  .menu-component {
    padding-top: 24px;
    padding-left: 16px;
    height: 124px;
    margin-top: 10px;
  }

  .sider-profile {
    padding-top: 10px !important;
    display: inline-flex;
    justify-content: space-between;
    //Commented this for MenuSider.js when narrow screen
    //margin-top: 10px;
  }

  .sider-message {
    padding-top: 3px !important;
    display: flex;
  }

  .content-title {
    padding-top: 40px;
  }

  .user-prof{
    margin-right:26px;
  }
}

@media (max-width: 700px) {
  .user-page .ant-layout-sider-dark {
    position: absolute;
    //Commented this for MenuSider.js when narrow screen
    //padding-left: 20%;
  }
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;