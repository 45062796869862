.page-sider .social-media {
    margin-top: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.page-sider .social-media .label {
    font-size: 16px;
    font-weight: 700;
    color: #2d4c68;
}

.page-sider .social-media .links {
    display: flex;
    flex-direction: column;
}

.page-sider .social-media .contact {
    display: flex;
    color: #2d4c68;
    font-weight: 600;
    margin-top: 12px;
}

.page-sider .social-media .contact-name {
    opacity: 0.6;
    color: #2d4c68;
    margin-left: 11px;
    max-width: 90%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
