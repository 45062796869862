.newsContent {
  padding-top: 100px;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-and-add-news {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;