.import-db {
    margin: 0 20% 10% 20em;
    flex-wrap: wrap;
}

.ant-list-item{
    padding: 0 !important;
}
.import-report{
    margin-top: 30px;
    margin-right: 10%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;

}

.load-excel-form {
    display: flex;
    flex-direction: column;
}

.warn-icon {
    color: #ec9700;
}

.error-icon {
    color: #ff4d4f;
}

.ok-icon {
    color: #4dffa3;
}
.import-page {
    flex-direction: column !important;
}

.import-db-list-item {
    padding: 0;
    margin: 0;
}
.import-warning{
    color:grey;
    text-align: center;
    font-size: 12px;
    margin-bottom: 15px;
}

.import-db-buttons {
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    display: flex;

}

.ant-form-item{
    margin-bottom: 10px;
}

.send-data-button {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.import-text-hint {
    color: #002766;
}