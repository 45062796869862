.add-children-btn {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FA8C16;
    vertical-align: middle;
    border: 0;
    margin: 5px;

}

.ant-btn:active, .ant-btn:focus {
    color: #FA8C16;
}

.ant-modal-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.ant-modal-title {
    font-weight: 700;
    font-size: 24px;
    color: #2D4C68;
    margin: 0;
    text-align: center;
}

.ant-modal-body {
    padding: 10px 20px 20px 20px;
}