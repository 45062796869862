.add-category-type {
    display: flex;
    flex-wrap: wrap;
}

.add-category-type .add-category-type-input {
    width: 200px;
    margin: 0 5px 0 5px;
}

.add-category-type .add-category-type-button {
    background: #FA8C16;
    color: #fff;
    margin-left: 10px;
}

.add-category-type .add-category-type-button:hover {
    background: #FFA940;
}

.add-category-upload {
    margin-right: 11px;
    color: #2E69C9;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}