.user-page {
}

@media (max-width: 1300px) {
  .user-page   .layout .global-content,
  .layout .header {
    padding: 0 10px 0 10px;
  }

  .layout .header {
    justify-content: flex-start;
  }
}

.ant-dropdown-trigger {
  cursor: pointer;
}


@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;