.modal-add-club .ant-modal-body {
    padding: 0;
    min-height: 600px;
}

@media screen
and (min-device-width: 1025px) {
    .modal-add-club .ant-modal-content {
        border-radius: 16px;
    }
}

@media screen
and (max-device-width: 1025px) {
    .modal-add-club .ant-modal-content {
        border-radius: 16px;
        position: inherit;
        /*margin-left: -145vw;*/
        /*margin-top: -150vh;*/
    }
}

.modal-add-club .add-club-container {
    /*display: flex;*/
    padding: 20px 40px 32px 24px;
    flex-direction: column;
    justify-content: space-between;
}

.modal-add-club .add-club-header {
    font-weight: 700;
    font-size: 24px;
    color: #2D4C68;
}

.modal-add-club .ant-modal-close {
    top: 15px;
    right: 15px;
}


