.search-and-add-questions {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.questionsContent {
    padding-top: 100px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
