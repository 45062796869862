.about-header {
    margin-bottom: 96px;
}

.search span {
    font-size: 18px;
}

@media (min-device-width: 320px) and (max-device-width: 568px) {
    .search
        .ant-select-show-search.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        margin-left: -24px;
    }

    .about-header .ant-carousel .slick-slider {
        margin-top: -60px;
        align-self: flex-start;
        text-align: center;
        font-size: 24px;
    }

    .about-carousel-block .carousel-item {
        height: 580px !important;
    }

    .ant-carousel .slick-dots {
        position: static;
        margin-top: 10px;
    }
}
@media (min-width: 345px) and (max-width: 824px) {
    ant-avatar-lg.ant-avatar-icon {
        margin-right: -4px;
    }
}
@media (min-width: 330px) and (max-width: 344px) {
    .ant-avatar-lg.ant-avatar-icon {
        margin-right: -11px;
    }
}

@media (min-width: 319px) and (max-width: 329px) {
    .ant-avatar-lg.ant-avatar-icon {
        margin-right: -8px;
    }

    .header .add-club-button {
        width: 139px;
        font-size: 15px;
        margin-left: -1.2vw;
        margin-right: 1.2vw;
    }
}

@media (min-width: 320px) and (max-width: 824px) {
    .about-header {
        margin-bottom: 45px;
    }

    span.ant-avatar.ant-avatar-lg.ant-avatar-circle.ant-avatar-icon.avatarIfNotLogin {
        margin-right: -15px;
        margin-left: -9px;
    }

    .center-side {
        margin-right: -22px;
    }

    .search-icon-group {
        display: inline-flex;
    }
    .header .right-side-menu {
        margin-left: auto;
    }
}
