.comment-modal .comment-edit-title {
    font-weight: 700;
    font-size: 24px;
    color: #2D4C68;
    text-align: center;
}


.comment-modal .comment-reply-title {
    font-weight: 700;
    font-size: 24px;
    color: #2D4C68;
    margin-bottom: 30px;
    text-align: center;
}


.comment-modal .ant-modal-body {
    height: 100%;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 6px 28px 28px;
}

.comment-modal .phone-icon {
    color: #8C8C8C;
    font-size: 18px;
    width: 20px;
}

.comment-modal .mail-icon {
    color: #8C8C8C;
    z-index: 10;
    font-size: 17px;
    width: 20px;
}

.comment-modal .comment-fields{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-right: 20px;
    margin-left: 18px;
}

.comment-modal .ant-form-item label {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    width: 94px;
    text-align: right;
    padding-right: 16px;
    display: block;
    color: #2D4C68;
}
.comment-modal .ant-form-item label:after {
    content: none;
}

.comment-modal .ant-form-item{
    width: 100%;
}

.comment-modal .comment-input-box{
    box-sizing: border-box;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    height: 40px;
}
.comment-modal .ant-form-item input {
    font-size: 16px;
    color: #595959;
    background: #FFFFFF;

}

.comment-modal .ant-form-item textarea {
    font-size: 16px;
    color: #595959;

    height: 118px;

    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 6px;
}

.comment-modal .do-comment-button{
    padding: 8px 70px;
    height: 40px;
    display: block;
    background: #FA8C16 !important;

    border-radius: 6px;

    transform: translateX(50%);

    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF !important;
    opacity: 1.0;
}

.comment-modal .do-comment-button:disabled{
    opacity: 0.4;
}

.comment-modal .complaint-note{
    font-size: 12px;
    line-height: 18px;
    margin: 0 51px 18px;
    text-align: center;
    color: #595959;
}

.comment-modal .club-title-note{
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;


    margin-bottom: 24px;
    text-align: center;

    color: #003A8C;
}

.comment-modal .ant-tabs-nav-list{
    margin: auto;
}
.comment-modal .ant-tabs-top > .ant-tabs-nav::before {
    border: none;
}

.comment-modal .ant-tabs-tab-btn{
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    z-index: 2;
    color: #8C8C8C;
    padding-bottom: 2px;

}
.comment-modal .ant-tabs-tab{
    padding-bottom: 6px;
    padding-top: 0px;
}

.comment-modal .ant-tabs-tab.ant-tabs-tab-active{
    border-bottom: 2px solid #FA8C16 !important;
}
.comment-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    padding-bottom: 0px;
    color: #FA8C16;
}
.comment-modal .comment-type-tabs{
    padding-top: 30px;
    padding-bottom: 18px;

}

.comment-modal .ant-tabs-ink-bar {
    height: 2px;
    background: #FA8C16 !important;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;