.clubInfo .container {
    padding: 40px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.clubInfo .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.clubInfo .club-name {
    margin-left: 7px;
    font-size: 18px;
    font-weight: bold;
    color: #002766;
    line-height: 28px;
}

.clubInfo {
    padding-bottom: 0;
    overflow: hidden;
    border-radius: 16px;
}

.clubInfo .ant-modal-body {
    display: flex;
    max-height: 944px;
    padding: 0;
}

.clubInfo .tags {
    width: 100%;
    margin-bottom: 10px;
}

.clubInfo .address-icon {
    font-size: 1.5em;
    color: #ffa62c;
    margin-right: 11px;
}

.clubInfo .address {
    margin-top: 15px;
    display: flex;
    margin-bottom: 20px;
}

.clubInfo .address .text {
    color: #2d4c68;
    opacity: 0.6;
    font-weight: 600;
    vertical-align: center;
}

.clubInfo .sider-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #2d4c68;
}

.clubInfo .years {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2d4c68;
    opacity: 0.6;
}

.clubInfo .age {
    margin-bottom: 20px;
}

.clubInfo .social-media {
    margin-bottom: 28px;
}

.clubInfo .social-media .links {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
}

.clubInfo .social-media .label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #2d4c68;
}

.clubInfo .social-media .links .contact {
    display: flex;
}

.clubInfo .social-media .links .contact .contact-name {
    margin-left: 10px;
    margin-right: 14px;
}

.clubInfo .rating .feedback {
    font-size: 14px;
    color: #0050b3;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 14px;
}

.clubInfo .more-button {
    font-weight: 700;
    height: 40px;
    width: 203px;
    background: #fa8c16;
    color: white;
    border-radius: 6px;
    font-size: 16px;
    margin-bottom: 18px;
}

.clubInfo .more-button:hover {
    background-color: #ffa940;
}

.clubInfo .about-club {
    margin-top: 30px;
}

.clubInfo .about-club .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #2d4c68;
}

.clubInfo .carousel {
    max-width: 820px;
    height: 120px;
    margin-bottom: 20px;
}

.clubInfo .carousel .carousel-cell {
    width: 120px;
    height: 100%;
    border-radius: 6px;
}

.clubInfo .about-club .description {
    height: 234px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #2d4c68;
    opacity: 0.9;
    overflow-y: auto;
}

.clubInfo .about-club .description::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: #f5f5f5;
    border-radius: 6px;
}

.clubInfo .about-club .description::-webkit-scrollbar-thumb {
    background-color: #bfbfbf;
    border-radius: 6px;
}

.clubInfo .modal-settings-btn {
    box-shadow: none;
    position: absolute;
    top: 16px;
    right: 56px;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    width: 28px;
    height: 28px;
}

.clubInfo .modal-settings-btn:focus,
.clubInfo .modal-settings-btn:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
}

.clubModal.popConfirm span {
    background: #fa8c16;
    color: white;
    border-radius: 6px;
    font-size: 16px;
    padding: 2px 14px 5px;
}

.clubModal.popConfirm span:hover {
    background-color: #ffa940;
}

.clubModal.popConfirm,
.clubModal.popCancel {
    padding: 0;
    margin-left: 16px;
    background: transparent;
    border: 0;
    text-shadow: none;
    box-shadow: none;
}

.clubModal.popCancel span {
    background: white;
    color: #fa8c16;
    border-radius: 6px;
    font-size: 16px;
    padding: 2px 14px 5px;
}

.clubModal.popCancel span:hover {
    background-color: #fa8c16;
    color: white;
}

.download-button {
    display: block;
    margin: 0 auto;
}
