.add-template {
	.pdf-upload-wrapper {
		.ant-upload-list.ant-upload-list-text {
			width: fit-content;
		}
	}
}

.add-template-upload-icon {
	color: #fa8c16;
}

.flooded-button:hover .add-template-upload-icon {
	color: #fff;
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;