.contacts {
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.contacts label {
    font-size: 18px;
    text-align: left;
    padding-right: 16px;
    display: block;
    color: #2D4C68;
    display: block;
}

.contacts .ant-row {
    display:block;
}
.addCenter .clubsOfCenter .center-btn{
    background: #FA8C16;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 24px;
    position: absolute;
    width: 190px;
    height: 40px;
    right: 0px;
    top: 0px;
}