.center-profile{
  width: 90%;
  margin-top: 32px;
  margin-left: 28px;
  margin-right: 86px;
  justify-content: space-between;
  padding-bottom: 32px;
}

.user-centers .centers-cards {
  display: block;
  min-width: 120px;
  justify-content: space-between;
  margin-bottom: 20px;
}

.aro-but {
  margin-left: 40px;
}

.aro {
  margin-top: 8px;
  padding-left: 10px;
}

.user-centers .user-centers-pagination {
  text-align:center;
}


.answerButton {
  margin-left: 10px;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;