@media (min-device-width: 400px) {
  .role-name {
    display: -webkit-inline-flex;
  }
}



@media (min-device-width: 321px)
and (max-device-width: 400px) {
  .role-name {
    font-size: initial;
  }
}

@media (max-device-width: 320px) {
  .role-name {
    margin-left: -7px;
    font-size: small;
  }
}

.user-edit .ant-modal-body {
  padding: 0 !important;
}

@media screen
and (min-device-width: 1025px) {
  .ant-modal-content {
    border-radius: 16px;
    height: auto;
  }
}

@media screen
and (max-device-width: 1025px) {
  .ant-modal-content {
    border-radius: 16px;
    height: auto;
  }
}

@media screen
and (max-device-width: 1025px) {
  .user-edit .ant-modal-content {
    border-radius: 16px;
    height: fit-content;
    margin: auto;
    width: 90%;
    align-content: center;
  }
}

@media screen
and (min-device-width: 1080px) {
  .user-edit .ant-modal-content {
    border-radius: 16px;
    height: fit-content;
  }
}

.ant-modal-password-content {
  border-radius: 16px;
  height: 340px;
}

.short-text {
  display: none;
}

@media screen
and (max-width: 370px){
  .user-edit .edit-header {
    width: 85%;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #2D4C68;
    font-weight: 700;
    border-radius: 16px;
    margin-top: 32px;
    //margin-left: 40px;
    margin-left: 5%;
  }
}

@media screen
and (min-width: 371px){
  .user-edit .edit-header {
    width: 85%;
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #2D4C68;
    font-weight: 700;
    border-radius: 16px;
    margin-top: 32px;
    margin-left: 40px;
  }
}

.user-edit .ant-modal-close-x {
  padding-right: 18px;
  margin-top: -5px;
}

.user-edit .ant-modal-close-icon {
  color: #595959;
  font-size: 22px;
}


@media screen
and (min-device-width: 320px) {
  .user-edit .button-container {
    padding-top: 24px;
    display: flex;
    height: 48px;
    padding-left: 40px;
    background: #FFFFFF;
  }

  .user-edit .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF7E6;
    height: 48px;
    width: 40%;
    font-size: 18px;
    line-height: 32px;
    border-radius: 4px;
  }
}

.user-edit .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

@media screen
and (min-device-width: 320px) {
  .user-edit .ant-radio-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF7E6;
    height: 48px;
    width: 46%;
    border: 0;
    font-size: 18px;
    line-height: 32px;
    border-radius: 4px;
    //margin: auto auto auto -10px;
  }
}

.user-edit .ant-radio-button-wrapper:hover,
.user-edit .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #FFFFFF;
  color: black;
  box-shadow: 0 2px 4px rgba(51, 51, 50, 0.12);
  z-index: 1;

  .ellipse {
    background: #FA8C16;
  }
}

.user-edit .ant-radio-button-wrapper:last-child {
  left: -5px;
}

.user-edit .button-box {
  display: flex;
}

@media (min-width: 320px) {
  .user-edit .ellipse {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    background: #FFC069;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.user-edit .user-icon {
  color: #FFFFFF;
  font-size: 21px;
}


.user-edit .ant-row {
  display: block;
  justify-content: flex-end;
}

.user-edit .user-edit-column {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}

.user-edit .user-edit-password {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-radius: 50%;
}

@media screen
and (max-device-width: 600px) {
  .user-edit .user-edit-input {
    height: 100%;
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen
and (min-device-width: 601px) {
  .user-edit .user-edit-input {
    height: 100%;
    width: 436px;
    font-size: 16px;
    line-height: 24px;
  }
}

.ant-form-item-label > label {
  color: #2D4C68;
}

//.user-edit .user-edit-box {
//    height: 40px;
//    width: 436px;
//    border-radius: 6px;
//}

.user-edit .user-edit-row .label {
  font-size: 18px;
  padding-right: 16px;
  color: #2D4C68;
}

.user-edit .ant-form-item-required {
  text-align: left;
}

@media screen
and (max-device-width: 450px) {
  .user-edit .submit-button {
    margin-left: -10%;
    padding: 8px 24px;
    min-inline-size: 200px;
    height: 40px;
    background: #FA8C16 !important;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF !important;
  }
}

@media screen
and (min-device-width: 451px) {
  .user-edit .submit-button {
    float: right;
    padding: 8px 24px;
    width: 270px;
    height: 40px;
    background: #FA8C16 !important;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF !important;
  }
}

@media (max-width: 450px) {
  .user-edit .user-edit-footer {
    float: right;
    margin: auto;
  }
}

@media (min-width: 451px) {
  .user-edit .user-edit-footer {
    float: right;
    margin-right: 40px;
  }
}

.align-checkbox .checkbox {
  border: 3px black;
  color: #D46B08;
  width: 17px;
  height: 17px;
}

.align-checkbox .checkbox-label-interval {
  margin-left: 20px;
  vertical-align: text-bottom;
  color: #2d4c68;
}

.align-checkbox {
  align-content: baseline;
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;