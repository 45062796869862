.page-sider .similar-clubs {
    margin-top: 60px;
}

.page-sider .similar-clubs .label {
    color: #002766;
    font-weight: 700;
    font-size: 24px;
}

.page-sider .similar-clubs .primitive-card {
    height: 100%;
    padding: 0 2rem;
}
