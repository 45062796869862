@item-height: 40px;

.certificate-by-template {
	padding-top: 40px;
	background: #faedde;

	.container {
		margin: 0 10% 0 10%;
	}

	.stage-header {
		margin-bottom: 10px;
	}

	.form {
		width: fit-content;
		margin-right: 0;
	}

	.configuration {
		display: flex;
		margin-top: 25px;
	}

	.form-item {
		height: @item-height;
		padding: 5px;
		display: flex;
		align-items: center;

		.field-name {
			padding-right: 10px;
			display: flex;
			align-items: center;
		}

		.ant-form-item-row {
			width: 100%;
		}

		.ant-form-item-control-input-content {
			display: flex;
			justify-content: space-between;
		}

		input {
			margin-right: 10px;
		}
	}

	.form-item-text {
		margin: 0;

		#input-text {
			width: 350px;
		}
	}

	.form-item-int {
		margin: 0 10px 0 0;

		.inputNumber-wrapper {
			width: 350px;
		}
	}

	.draggable-list-wrapper {
		position: relative;
		user-select: none;
		min-width: 25%;
	}

	.draggable-list {
		cursor: url('http://gtms02.alicdn.com/tps/i2/T1_PMSFLBaXXcu5FDa-20-20.png') 10 10, pointer !important;
		position: relative;
		height: 305px;
	}

	.draggable-list-element {
		display: flex;
		align-items: center;
		height: @item-height;
		transition: box-shadow .5s, transform .5s;
	}

	.draggable-list-element-content-wrapper {
		background-color: rgba(#fff, .9);
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 4px;
		height: 90%;
		width: 100%;
	}

	.draggable-list-element.list-drag-selected {
		box-shadow: 0 8px 20px #E6E6E6;
		transform: scale(1.1) !important;
	}

	.draggable-list-text {
		padding: 0 15px;
	}

	.draggable-list-text p {
		font-size: 12px;
		margin-bottom: 0;
	}

	.draggable-list-checkbox {
		padding-right: 15px;
	}

	@media screen and (max-width: 320px) {
		.draggable-list-text h1 {
			font-size: 14px;
			line-height: 28px;
		}
	}

	.certificate-data-control-wrapper {
		button {
			margin: 0 0 10px;
		}
	}

	.validation-result-wrapper {
		display: flex;
		justify-content: flex-start;
		border: transparent;
		background: rgba(#fff, .9);
		border-radius: 4px;
		margin: 3px;

		span {
			&:nth-child(1) {
				width: 5%;
			}

			&:nth-child(2) {
				width: 92%;
			}
		}

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 4px;
		}
	}

	.import-wrapper {
		margin: 25px 0;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	.google-form_wrapper {
		width: 50%;
		color: #002766;
	}

	.google-form_title_wrapper {
		position: relative;
		width: fit-content;

		#question-ico {
			color: red;
			position: absolute;
			bottom: 80%;
			left: 101%;
			cursor: pointer;
		}

	}

	.validation-result-list {
		min-height: fit-content;
		max-height: 300px;
		overflow: scroll;
		overflow-x: hidden;
	}

	.download-float-button-wrapper {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		padding-right: 10px;

		button {
			border-radius: 20px;
			margin-top: 5px;
			margin-bottom: 20px;
			text-align: right;
		}
	}

	&_modal {
		top: 5%;
		border-radius: 20px;
		overflow: hidden;

		.ant-modal-close-x {
			display: none;
		}
	}

	&_help-image_wrapper {
		border-radius: 20px;
		width: 800px;
		height: 600px;
		overflow: auto;
		position: relative;

		img {
			position: absolute;
		}

	}

	&_modal_tip {
		position: absolute;
		padding: 15px;
		background-color: rgba(255, 255, 255, .7);
		border-radius: 20px;
		border: 1px solid red;
		backdrop-filter: blur(5px);
		box-shadow: 0 0 40px rgb(0 0 0 / 45%);

		&.tip-1 {
			top: 30%;
			left: 2.5%;
			width: 40%;
			cursor: pointer;
		}

		&.tip-2 {
			top: 139%;
			right: 2%;
			width: 27%;
			border: 1px solid @primary-color;
		}

		&.tip-3 {
			top: 195%;
			left: 10%;
			width: 80%;
			text-align: center;
			background-color: #fff;

			.warning-ico {
				color: @primary-color;
			}
		}
	}
}

.add-template-upload-icon {
	color: #fa8c16;
}

.flooded-button:hover .add-template-upload-icon {
	color: #fff;
}

@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;