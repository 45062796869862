.club-list .club-list-content .list-rectangle-item {
    width: 100%;
    height: fit-content;
}

.club-list-content .list-rectangle-item .ant-card-body {
    width: 100%;
    height: 104%;
    flex-direction: column;
    justify-content: space-between;
}

.club-list-content .list-rectangle-item .item-rectangle-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    flex-wrap: wrap;
}

.club-list-content .list-rectangle-item .center-description {
    width: 150px;
}

.club-list-content .list-rectangle-item .details-button {
    width: 30%;
}
.club-list-content .list-rectangle-item .address {
    width: 240px;
}
.club-list-content .list-rectangle-item .rating {
    width: 150px;
}

.club-list-content .list-rectangle-item .item-rating-address {
    display: flex;
    align-items: flex-end;
}

@media (max-width: 480px) {
    .club-list-content .list-rectangle-item .details-button {
      display: none;
    }
  }