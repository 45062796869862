.map-modal {
    padding-bottom: 0;
    overflow: hidden;
    border-radius: 16px;
}

.map-modal .ant-modal-body {
    display: flex;
    height: 700px;
    padding: 0;
}

.map-modal .ant-modal-close-x {
    background: white;
    width: 48px;
    height: 48px;
    line-height: 48px;
}

.map-modal .gm-style .gm-style-iw-c {
    padding: 0;
    border-radius: 16px;
}

.map-modal .gm-ui-hover-effect {
    width: 40px!important;
    height: 40px!important;
    right: 11px!important;
}

.map-modal .gm-ui-hover-effect img {
    width: 25px!important;
    height: 30px!important;
}

.map-modal .gm-style-iw-d {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 450px;
    height: 269px;
    overflow: hidden!important;
}

.map-modal .gm-style-iw-c {
    box-shadow: 0 100px 80px rgb(1 16 44 / 3%), 0 12.5216px 10.0172px rgb(1 16 44 / 4%);
}

.map-modal .gm-style-iw-t::after {
    box-shadow: none;
}

.map-modal .gm-style-iw-c,
.map-modal .gm-style-iw-t::after {
    top: -59px;
}

@media (max-width: 1200px) {
    .map-modal {
        width: 95% !important;
    }
}

@media (max-width: 950px) {
    .map-modal .map-layout {
        flex-direction: column;
        width: 100%;
    }
}