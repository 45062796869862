.certificates {
    margin-top: 40px;
}

.certificates .ant-list-item {
    padding: 20px !important;
    background: white;
    border-radius: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.certificates h3 {
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    color: #2D4C68;
}

.certificates div, i {
    font-size: 16px;
    color: #2D4C68;
}

.certificates button {
    padding: 0;
    width: 140px;
    height: 40px;
    font-size: 16px;
    font-weight: bolder;
    border-radius: 6px;
    background: #FA8C16;
    color: #FFFFFF;
}

.certificates button:hover {
    background: #FFA940;
}

.certificates button:focus {
    background: #FA8C16;
}

.user-content-pagination {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.user-content-pagination .ant-pagination-item-link:hover {
    color: #000;
    background: @primary-hover-color;
}

.user-content-pagination .ant-pagination-item-link {
    color: #000;
}

.user-content-pagination .ant-pagination-item:hover,
.user-content-pagination .ant-pagination-item-active {
    text-decoration: none;
    background-color: @primary-hover-color;
}

.download-modal .ant-spin-dot-item {
    background-color: #FFA940;
}

.download-modal .ant-modal-content, .download-modal .ant-modal-header {
    text-align: center;
    margin: auto;
    width: 300px;
    border-radius: 30px;
}

@media (max-width: 800px) {

    .certificates .ant-list-item {
        display: block;
        align-items: initial;
    }

    .certificates .ant-list-item-action {
        padding-top: 10px;
        margin: 0;
    }
}
  
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;