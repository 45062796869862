.createNewsModal {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  font-weight: 700;
  font-size: 24px;
  color: #2D4C68;
  margin-bottom: 25px;
}

.form {
  width: 95%;
}

.add-news-upload {
  margin-right: 11px;
  color: #2E69C9;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.datePickerSelectedDay {
  color: black;
  border: 2px solid black;
  border-radius: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #1890ff;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: none;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.news-button {
  //width: 200px;
}

.formButton {
  //padding: 8px 70px;
  width: 180px;
  height: 40px;
  background: #FA8C16 !important;
  border-radius: 6px;
  transform: translateX(50%);
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF !important;
  opacity: 1.0;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;