.club-list-content {
  margin: 45px auto 0 auto;
  display: flex;
  flex-direction: column;
}

.club-list-content .content-clubs-list {
  min-height: 932px;
  padding-top: 35px;
  margin-right: 20px;
}

.club-list-content .content-clubs-block {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.club-list-content .content-clubs-list:last-child {
  margin-bottom: 0;
}

.club-list-content .content-clubs-list .ant-card-body {
  padding: 0;
  width: 248px;
  height: 370px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.club-list-content .content-clubs-list .empty .ant-card-body {
  padding: 0;
}

.club-list-content .content-clubs-list .ant-card-head {
  border-bottom: none;
}


.club-list .club-list-content .card {
  height: 418px;
  width: 296px;
  color: black;
  box-shadow: 0px 100px 80px rgba(1, 16, 44, 0.03), 0px 12.5216px 10.0172px rgba(1, 16, 44, 0.035);
  border-radius: 16px;
  padding: 24px;
  margin: 0 5px 28px 5px;
}

.club-list .club-list-content .card .ant-card-head-title {
  white-space: normal;
}

.club-list .club-list-content .content-clubs .ant-space-item {
  margin-right: 0;
  padding-bottom: 0;
}

.club-list .club-list-content .card .title {
  display: flex;
  align-items: center;
  line-height: 22px;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.club-list .pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.club-list .club-tags-box {
  display: flex;
  align-items: flex-start;
  height:50px;
}

.club-list .club-tags {
  width: 180px;
}

.club-list .and {
  margin-top: auto;
  margin-bottom: 0;
}
.club-list .expand-icon{
  margin-top: 5px;
}

.club-list .club-online {
  display: flex;
  align-items: center;
  color: #2E69C9;
  opacity: 0.9;
}


.club-list .card .address .oneAddress{
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow:hidden;
  max-width:199px;
  color: #2D4C68;
  font-weight: 600;
  opacity: 0.6;
  cursor: pointer;
}

.club-list .card .address .popover{
  display:flex;
}

.club-list .card .address .text{
  display:flex;
}


.club-list .card .address .text .oneAddress{
  max-width: 135px;
  opacity: 1;
}

.club-list .club-online-icon {
  font-size: 1.4em;
  margin-left: 4px;
  margin-right: 11px;
}

.club-list .club-online-label {
  font-weight: 500;
}

@media (max-width: 1264px) {
  .club-list-content {
    max-width: 90% !important;
  }
}

/*@media (max-width: 630px) {
    .club-list-content {
        display: none;
    }
}*/



@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;