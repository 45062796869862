.challenge-page .details-back {
    width: 293px;
    height: 48px;
    background: #FA8C16;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 6px;
    font-size: 18px;
}

.challenge-page .button-alain {
    text-align: center !important;
}

.challenge-page .details-button {
    width: 185px;
    height: 48px;
    background: #FA8C16;
    border: none;
    color: white;
    font-weight: 700;
    border-radius: 6px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.challenge-page .google-form {
    display: flex;
    align-items: center;
    justify-content: center;
}