//.ant-menu-horizontal {
//	&:not(.ant-menu-dark) {
//		> .ant-menu-item,
//		> .ant-menu-submenu {
//			&:hover,
//			&-active,
//			&-open,
//			&-selected {
//				color: white;
//
//				&::after {
//					border-bottom: 2px solid white;
//				}
//			}
//		}
//	}
//}

.menu-hamburger-mobile{
	font-size: 20px !important;
}

.ant-menu-overflow-item-rest{
	padding: 0 !important;
}
@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;