.extra {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date {
  color: #2D4C68;
  opacity: 60%;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.exclamation {
  color: #FA8C16;
  font-size: 18px;
  margin-left: 14px;
}

.checkCircle {
  color: #52c41a;
  font-size: 18px;
  margin-left: 14px;
}

.userInfo {
  position: absolute;
  margin: 63px 0 0 40px;
  display: flex;
  align-items: center;
}

.userName {
  margin: 0 0 0 12px;
  height: 16px;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  color: #2D4C68;
}

.deleteButton {
  color: red;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}


@primary-color: #FA8C16;@button-color: #FA8C16;@link-color: #000;@text-color: #000;@primary-hover-color: #FA8C16;@primary-hover-color_2: #FFA940;@menu-highlight-color: #FFF;